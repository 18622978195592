.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.color-theme {
  background: var(--header-color);
  color:  var(--primary-color);
  padding: 24px;
}
a {
  color: var(--primary-color);
}
a:hover {
  filter: brightness(var(--hover-brightness));
}
.color-primary{
  color: var(--primary-color);
}
/**Footer**/
.footer {
  background: var(--secondary-color);
  padding: 45px 0 20px;
  font-size: 15px;
  line-height: 24px;
  color: var(--primary-color);
}

.footer .text-justify {
  text-align: justify;
  text-justify: distribute;
}

.footer hr {
  border-top-color:var(--primary-color);
  opacity: 0.5
}

.footer hr.small {
  margin: 20px 0
}

.footer a {
  color: var(--primary-color);
}

.footer a:hover {
  color: var(--primary-color);
  text-decoration: none;
  filter: brightness(var(--hover-brightness));
}

.footer h6 {
  color: var(--primary-color);
  font-size: 16px;
  text-transform: uppercase;
  margin-top: 5px;
  letter-spacing: 2px
}

.footer-links {
  padding-left: 0;
  list-style: none
}

.footer-links li {
  display: block
}

.footer-links a {
  color: var(--primary-color);
  text-decoration: none;
}

.footer-links a:active,
.footer-links a:focus,
.footer-links a:hover {
  color: brightness(var(--hover-brightness));
  text-decoration: none;
}

.footer-links.inline li {
  display: inline-block
}

.copyright-text {
  margin: 0
}

.footer-powered {
  text-align: right;
}

.footer .bible-verse{
  font-weight: 600;
}

.footer .bible-verse:before{
    content: '“ ';
    font-family: FontAwesome;
    font-size: 20px;
}
.footer .bible-verse::after{
  content: ' ”';
  font-family: FontAwesome;
  font-size: 20px;
}

@media (max-width:991px) {
  .footer [class^=col-] {
    margin-bottom: 30px
  }
}

@media (max-width:767px) {
  .footer {
    padding: 1em 2em 0;
  }

  .footer .copyright-text,
  footer .social-icons,
  .footer-powered {
    text-align: center
  }
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px
}

.social-icons li.title {
  margin-right: 15px;
  text-transform: uppercase;
  color: #96a2b2;
  font-weight: 700;
  font-size: 13px
}

.social-icons a {
  color: var(--primary-color);
  font-size: 16px;
  display: inline-block;
  line-height: 44px;
  margin: 0 1em 0 1em;
  text-align: center;
  margin-right: 8px;
  -webkit-transition: all .2s linear;
  -o-transition: all .2s linear;
  transition: all .2s linear
}

.social-icons a.firstElement {
  margin-left: 2px;
}

.social-icons a:hover {
  color: var(--secondary-color);
}

.social-icons.size-sm a {
  line-height: 34px;
  height: 34px;
  width: 34px;
  font-size: 14px
}



@media (max-width:767px) {
  .social-icons li.title {
    display: block;
    margin-right: 0;
    font-weight: 600
  }

}



/*.curriculum-card {
  opacity: 0;
  transform: translateY(15px);
  transition: opacity 1s ease, transform 1s ease;
}

.curriculum-card.visible {
  opacity: 1;
  transform: translateY(0);
}*/


.orText:after,
.orText:before {
  background: var(--primary-color);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 10%;
}

.loginOrText:after,
.loginOrText:before {
  background-color: #dadce0;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 30%;
}

.bounce-up-modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 80px;
  /* Location of the box */
  margin-left: 0px;
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
}

.bounce-up-modal-content {
  box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.5);
  border: 1px solid gray;
  border-bottom: none;
}

.card-image-container{
  /*max-height: 280px;
  max-width: 280px;*/
}
.image-view-img {
  width: 100%;
  height: auto
}

.custom-file-fileName {
  width: 30% !important;
}

.custom-file-choose-file-label:hover {
  background-color: #dee2e6 !important;
}

.input-required:after {
  content: " *";
}

li.fieldError {
  /*border: 1.5px solid red !important;*/
}


.ul-horizontal {

}

.ul-horizontal ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  display: flex;
}

.ul-horizontal ul.align-right {
  justify-content: end;
}


.ul-horizontal ul li {
  float: left;
  margin-left: 12px;
}

.ul-horizontal ul li a {
  display: block;
  color: white;
  text-align: center;
  padding: 6px;
  text-decoration: none;
  width: 36px;
}

.ul-horizontal ul li.active {
  border: 1px solid var(--primary-color);
  border-radius: 6px;
}

.ul-horizontal ul li a:hover {
  cursor: pointer;
  filter: brightness(var(--hover-brightness));
}

.grey-text {
  color: #8c8e93 !important;
}

img.cardImg {
  padding: 5px;
  border-radius: 8px 8px 8px 8px;
}

.modal-content {
  background-color: #fff;
}

.form-control {
  margin-bottom: 12px;
}

.changeImg {
  text-align: right;
  color: var(--primary-color);
}

.changeImg:hover {
  cursor: pointer;
}

.grid-context-menu button.btn {
  background-color: unset;
  border: unset;
  color: var(--primary-color);
  padding: unset;
}

.grid-context-menu button.btn:hover {
  background-color: unset;
  border: unset;
  filter: brightness(var(--hover-brightness));
}

.grid-context-menu {
  text-align: end;
}

.bg-grey {
  background-color: #808080;
}

.curriculum-card {
  border: 0.5px solid lightgray;
}
.curriculum-card:hover{
  cursor: pointer;
}

.curriculum-row {
  cursor: pointer;
}

.curriculum-row:hover {
  box-shadow: 1px 1px 6px lightgray;
}

ul.curriculum-data li {
  padding: 10px;
}

ul.curriculum-data li span {
  color: gray;
  padding: 10px;
}

.lesson-border {
  flex-shrink: 0;
  border: calc(8px + 0.2vw) solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
  box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.5);
}
.blog-border {
  flex-shrink: 0;
  border: calc(8px + 0.2vw) solid transparent;
  background-origin: border-box;
  background-size: cover;
  box-sizing: border-box;
  box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.5);
}

.border-green-yellow {
  border-radius: 1%;
  background-image: var(--img-url), linear-gradient(#f9f047, #0fd850) !important;
}

.border-pink-blue {
  background-image: var(--img-url), linear-gradient(to bottom right, #ff3cac, #562b7c, #2b86c5);
}

.border-orange-yellow {
  background-image: var(--img-url), linear-gradient(to bottom left, #f83600, #f9d423);
}

.border-box-shadow {
  box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.5);
}
.border-box-shadow.multi-steps{
  padding:20px;
}

.modal-custom-close-btn {
  float: right;
  font-size: x-large;
  z-index: 2;
  position: relative;
  cursor: pointer;
}

.admin-grid-card-overflow {
  max-height: 400px;
  overflow: auto;
  min-height: 400px;
  font-size: 1rem;
}

.form-border {
  flex-shrink: 0;
  border: calc(8px + 0.2vw) solid transparent;
  background-origin: border-box;
  background-clip: content-box, border-box;
  background-size: cover;
  box-sizing: border-box;
  box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.5);
}

.submitted-on-time {
  box-shadow: 0 0 6px 2px #0fd850;
}

.submitted-delayed {
  box-shadow: 0 0 6px 2px #f86300;
}

input[type=checkbox] {
  border: 1px solid;
}

input[type=radio] {
  border: 1px solid;
}

.login-header {
  padding: .3em 0em;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 10px 0px;
  border-bottom: 4px solid var(--primary-color);
  /*2px solid rgb(235, 10, 30)*/
  color: var(--primary-color);
}
a.logo-text-href{
  text-decoration: none ;
  color: var(--primary-color);
  position: relative;
  top: 2px;
}
.logo-text-new{
  text-transform: uppercase;
  margin-top: 6px;
  font-weight: 700;
  cursor: pointer;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--primary-color);
  letter-spacing: 1.2px;
  position: relative;
  top: 4px;
}

.logo-sup-text{
  font-size: small;
  /*top: -1.5em !important;*/
  font-weight: 600;
  text-transform: lowercase;
}
.logo-text, .logo-sup-text{
  font-family: "Gill Sans", "Gill Sans MT", Calibri, sans-serif; 
  font-style: normal; 
  font-variant: normal;
}
.mobile-logo-sup-text{
  top: -1.2em !important;
  font-size: 8px;
}

.header-menu-collapse{
  padding-left: 1em;
  text-transform: uppercase;
  font-size: .92em;
}
.logo-text-big-letter{
  font-size: 30px;
  font-style: normal; 
  font-variant: normal;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--primary-color);
  font-weight: 900;
  /*margin-right: 1px;*/
}
.mr-3{
  margin-right: 3px;
}

.nav-menu-close-btn button{
  border: unset;
}
.dss-wrapper {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center
}

.loginMessage {
  border: 1px solid rgba(var(--bs-primary-rgb));
  color: rgba(var(--bs-primary-rgb));
}

.dss-beadcrumb {
  font-size: smaller;
}

.login-form-title {
  text-align: center;
  font-weight: 800;
  padding: 15px;
  margin-bottom: 12px;
  font-size: x-large;
  color: var(--primary-color);
}

.login-form-welcome {
  text-align: center;
  font-weight: 800;
  padding: 15px;
  color: var(--primary-color);
  font-size: larger;
}

.login-btn, .activate-profile-btn {
  text-align: center;
  margin-top: 1rem;
}

.login-btn button, .activate-profile-btn button {
  width: 50%;
}

.login-recovery-container, .mobile-login-recovery-container{
  display: flex;
  flex-direction: row;
  -webkit-box-pack: justify;
  justify-content: center;
  gap: 50px;
  align-items: center;
  width: 80%;
  margin: 2rem auto;
}

.login-create-account {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  width: 100%;
  margin: auto;
  text-align: center;
}
.mobile-login-create-account .text {
  font-size: 12px;
}
.login-create-account .text {
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 20px;
  letter-spacing: normal;
  text-align: center;
  color: rgb(102, 102, 102);
  margin-right: 30px;
}

.login-recovery-container a,
.login-create-account a {
  font-size: 14px;
  font-weight: 600;
}

.mobile-login-recovery-container a,
.mobile-login-create-account a {
  font-size: 12px;
  font-weight: 600;
}

.btn-progress-spinner {
  margin-right: 8px;
  color : var(--secondary-color);
}
.btn-progress-spinner-right {
  margin-left: 8px;
}

.register-form label {
  font-weight: 400;
}

.error-text {
  color: red;
}

.exclamation-text {
  color: #ff7f00;
}
.input-error{
  border-color: red;
}
.icon-text {
  color: #343a40;
  margin-left: 10px;
  font-style: normal;
  font-size: larger;
}
.register-form-container{
  padding: 20px;
  margin-top:20px;
}
.register-form-container label {
  font-weight: 450;
}
.login-btn-group{
  margin-top: 30px;
}

.register-review .form-control{
  padding: unset;
}
.copy-icon{
  font-size: medium;
  cursor:pointer;
  padding: 10px;
}
.vendor-login-container{
  text-align: center;
  width:40%;
  margin: 0 auto;
}
.vendor-login-btns{
  padding:10px;
}
.google-login-btn{
  border:1px solid #dadce0;
  border-radius: 8px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 12px 24px;
}
.google-login-btn:hover{
  border-color:#dadce0;
  cursor: pointer;
}

.hide-ribbon{
  display: none;
}

.date-text{
  color: #1e2a35bf;
  text-align: left;
  margin-bottom:unset;
  margin-top: -8px;
}
.score-section{
  color: #1e2a35bf;
}

.log-in-btn{
  cursor: pointer;
  /*padding: 5px 8px;*/
}
.log-in-btn:hover{
 /* border: 1px solid var(--primary-color);
  border-radius: 6px;
  background: var(--primary-color);
  color: var(--secondary-color);*/
}
.input-error-container{
  font-size: small;
}

.user-menu{
  border: unset;
  filter: drop-shadow(0px 0px 12px rgba(29, 30, 32, .16));
  box-shadow: 0 1px 4px #00000042;
  color:#36344d;
}
.user-menu .user-menu-item{
  color:#36344d;
  font-size: 1rem;
  padding: .5em;
}
.user-menu .user-menu-item:active{
  background-color: unset;
  color: unset;
}
.user-menu-name{
  padding:.6em 0 0 .8em;
  cursor: unset !important;
  font-weight:500;
  color: var(--primary-color);
}
.user-menu-name:hover{
  cursor: unset !important;
  color: var(--primary-color);
  background: unset;
}
.user-menu-id{
  padding:.2em 1em 0 .8em !important;
  cursor: unset !important;
  font-size: smaller;
}
.user-menu-id:hover{
  cursor: unset !important;
  background: unset;
}
.user-menu-log-out-btn{
  padding: .3em;
  padding-left: 4em;
  padding-right: 4em;
  text-align: center;
  border-radius: 6px;
  border:1px solid  var(--primary-color);
  color: var(--primary-color);
}
.user-menu-log-out-btn:hover{
  background: var(--primary-color);
  color: var(--secondary-color);
}
.user-menu-logout{
  margin-top: 2em;
  border-top:1px solid  rgba(0, 0, 0, 0.175);
  padding-top: 1.2em;
  padding-bottom: .7em;
  text-align: center;
}
.user-menu-logout:active{
  background-color: unset;
  color: unset;
}

.collapse-section{
  padding:1em;
}
.collapse-section label {
  font-weight: 700;
}

.collapse-section a {
  padding: 1em;
  font-weight: 700;
}
.spin-color-primary{
  color: var(--primary-color);
}
.sidebarNav li a{
  padding-top: .5rem !important; 
  padding-bottom: .5rem !important;
}
.logo-container{
 /* margin-left: 1em ;*/
}


/**Mobile fixes **/
.logo-container-mobile{
  margin: auto;
}
.btn-no-border {
  border: unset;
}

.mobile-form-score{
  font-size: 8px;
}
.mobile-form-score button i{
  font-size: 8px !important;
}
.empty-text{
  text-align: center;
}
.color-info-badges span {
  padding-right: .4em;
}
.green-check-mark{
  color:green;
}
.color-red{
  color: red;
}
.icon-label {
  color: #343a40;
  margin-left: 10px;
  font-style: normal;
  font-size: larger;
}

.blog-detail-container img{
  margin-left: auto;
  margin-right: auto;
  display: block;
  max-height: 20em;
  max-width: 20em;
}

.blog-detail-container .iframe-container{
  display: block;
  width: 100%;
  height: 100%;
}


.blog-detail-container .iframe-content{
  margin-left: auto;
  margin-right: auto;
  display: block;
  width: 100%;
  max-width: 560px; 
}

.custom-big-modal{
  width: 90vw;
  max-width: none;
  margin: auto;
}

.blog-feed-item-title{
  float: left;
  margin: 0 0 8px 0;
  max-width: calc(100% - 48px);
  color:var(--primary-color);
  font-weight: 600;
  font-family: var(--bs-body-font-family);
}
.blog-feed-item-share-btn:active{
  transform: scale(0.75);
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
}
.blog-feed-item-share-btn{
  margin-left: 0;
  float: right;
  position: relative;
  font-size: x-large;
  color:#212121;
  cursor: pointer;
  
}
.blog-feed-item-date-header{
  margin: 0;
  clear: left;
  position: relative;
  display: inline-block;
  width: 100%;
  line-height: 24px;
  margin-top: 8px;
  vertical-align: top;
}

.blog-feed-item-body-container{
  color: #757575;
  line-height: 1.6em;
  margin: 1.5em 0 2em 0;
  display: block;
}

.blog-feed-item-body-container .blog-feed-thumbnail{
  float: left;
  margin: 0;
  margin-right: 2em;
  max-height: 128px;
  max-width: 128px;
}

.blog-feed-item-body-container .blog-feed-thumbnail img {
  max-width: 100%;
}
.blog-feed-item-text-container{
  max-height: 140px;
  margin: 0;
  position: relative;
  overflow: hidden;
}
.blog-feed-item-text-container .blog-feed-item-text{
  line-height: 24px;
}
.blog-feed-item-fader{
  background: linear-gradient(to left, #ffffff 0, #ffffff 20%, rgba(255, 255, 255, 0) 100%);
  color: #757575;
  height: 24px;
  right: 0;
  bottom: 0;
  box-sizing: border-box;
  position: absolute;
  width: 96px;
}
.blog-feed-item-fader::after{
  content: "\2026";
  float: right;
}
.blog-feed-item-footer-container{
  -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
.blog-feed-item-read-more-btn{
  cursor: pointer;
  font-weight: 700;
  text-transform: uppercase;
  border-radius: 2px;
  padding: 8px;
  margin: -8px;
  
}
.blog-readmore {
  padding: .5em;
  float: right;
  text-decoration: none;
}
.blog-readmore:hover{
  background-color: #f0efef;
}
.image-pos-center{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50% !important;
}

.blog-grid-item-menu-container{
  padding-top: 8px;
}

/**Blog SideBar archived accordion **/
.blog-menu-accordion{
  --bs-accordion-bg: #fff;
  --bs-accordion-border-color:unset;
}
.blog-menu-accordion .accordion-item{
  border-bottom: .3px solid gray;
  padding-bottom: 4px;
}
.blog-menu-accordion .accordion-item:last-of-type{
  border-bottom:unset;
}

.blog-menu-accordion .accordion-button:not(.collapsed) {
  background-color: #fff;
}
.blog-menu-accordion .accordion-button {
  font-weight: 500;
  padding: .8em;
}
.blog-menu-accordion .accordion-button:focus {
  box-shadow:none
}

.blog-menu-accordion .accordion-body{
  padding-top: 0em;
  padding-bottom: 0em;
  padding-left: 0em;
}
.blog-menu-accordion .accordion-body a, .blog-side-bar a {
  color: #757575 !important;
}
.blog-menu-accordion .accordion-body a.active, .blog-side-bar a.active {
  color: #fff !important;
}

.ant-ribbon-wrapper{
  font-family: unset !important;
}
.ant-alert-success, .ant-alert-info{
  border:1px solid var(--primary-color);
  background: var(--primary-color-alert-success) !important;
}
.ant-alert-success .ant-alert-icon {
  color: var(--primary-color) !important;
}
.ant-message-success{
  color: var(--primary-color) !important;
}
.ant-message-success .anticon{
  color: var(--primary-color) !important;
}
.ant-switch-checked{
  background: var(--primary-color) !important;
}
.ant-switch-checked:hover{
  background: var(--primary-color) !important;
}
.ant-spin{
  color: var(--primary-color) !important;
}

.curriculum-map-multiselect .ant-select-selection-item{
  background-color: var(--primary-color) !important;
  color: var(--secondary-color)!important;
}
.ant-select-item-option-selected{
  background-color: var(--primary-color-alert-success)!important;
}

/**Comments**/
.blog-comments-area{
  margin-top: 4em;
}
.comment-reply-area{
  padding: 1em;
}
.blog-emotion-bar{
  float:right;
}
.emotion-bar span{
  padding: 4px;
  color: rgba(0, 0, 0, 0.65);
}

.fade-text{
  color:#808080
}

@media (max-width:620px) {
  .container {
    padding-left : unset;
    padding-right: unset;
  }
  .submision-details-info{
    font-size: 10px;
  }
  .breadcrumb-item{
    font-size: 12px;
  }
  .mobile-login-recovery-container a{
    font-size: 10px;
  }
  .logo-sup-text{
    font-size: .6em;
  }
  .blog-feed-item-text{
    margin-top:1em
  }
  .blog-feed-item-image{
    width:100%
  }
  .blog-title{
    margin-top: 10px;
  }
  
}


.dropdown .drop-down-btn{
  padding: unset;
}
.nav-link {
  color:var(--primary-color)
}
.nav-link:focus{
  color: var(--primary-color);
  filter: brightness(var(--hover-brightness));
}
.nav-link:active{
  color: var(--primary-color);
  filter: brightness(var(--hover-brightness));
}
.nav-link:visited{
  color: var(--primary-color);
  filter: brightness(var(--hover-brightness));
}
.nav-link:hover{
  color: var(--primary-color);
  filter: brightness(var(--hover-brightness));
}
.welcome-username{
  font-weight: 500;
  font-size: 1em;
  color: var(--primary-color);
  cursor: pointer;
  text-transform: uppercase;
}
.welcome-username-dropdown, .mobile-view-coin{
  position: relative;
  top: 4px;
}
.btn-primary {
  --bs-btn-color: var(--secondary-color);
  --bs-btn-bg: var(--primary-color);
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-hover-color: var(--secondary-color);
  --bs-btn-focus-shadow-rgb: 64, 154, 233;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--secondary-color);
  --bs-btn-disabled-bg: var(--primary-color);
  --bs-btn-disabled-border-color: var(--primary-color);
  --bs-btn-hover-bg:var(--primary-color);
  --bs-btn-hover-border-color:var(--primary-color);
}
.btn-outline-primary {
  --bs-btn-color: var(--primary-color);
  --bs-btn-border-color: var(--primary-color);
  --bs-btn-hover-color: var(--secondary-color);
  --bs-btn-hover-bg: var(--primary-color);
  --bs-btn-hover-border-color: var(--primary-color);
  --bs-btn-focus-shadow-rgb: 30, 136, 229;
  --bs-btn-active-color: var(--primary-color);
  --bs-btn-active-bg: var(--primary-color);
  --bs-btn-active-border-color: var(--primary-color);
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--primary-color);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: var(--primary-color);
  --bs-gradient: none;
}

.bi{
  /*color:var(--primary-color);*/
}
.anticon{
  /*color:var(--primary-color) !important;*/
}
.bi-icon-fill{
  color: var(--primary-color) !important;
}
.form-check-input:checked {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:hover{
  background: var(--primary-color);
  filter: brightness(var(--hover-brightness));
  border-color:var(--primary-color);
  box-shadow: inset 0 3px 5px var(--primary-color);
}
.btn-primary:active{
  background: var(--primary-color);
  filter: brightness(var(--hover-brightness));
  border-color:var(--primary-color);
}

.ant-pagination .ant-pagination-item-active {
  border-color:var(--primary-color);
}
.ant-pagination .ant-pagination-item-active:hover{
  border-color:var(--primary-color);
}
.ant-btn-variant-outlined:hover{
  border-color: var(--primary-color) !important;
  color: var(--primary-color)!important;
}
.ant-btn-variant-solid{
  background: var(--primary-color) !important ;
}
.ant-btn-variant-solid:hover{
  filter: brightness(var(--hover-brightness)) !important ;
}
.ant-btn-variant-solid:focus{
  outline: 3px solid var(--primary-color) !important ;
}
.ant-steps .ant-steps-item-process .ant-steps-item-icon{
  background-color: var(--primary-color)!important;
  border-color: var(--primary-color)!important;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon >.ant-steps-icon{
  color: var(--primary-color)!important;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-icon{
  background-color: var(--secondary-color)!important;
  border-color: var(--primary-color)!important;
}
.ant-steps .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title::after {
  background-color: var(--primary-color)!important;
}


.layout-field-li{
  border: 1px solid var(--primary-color);
}
.layout-field-li-drag{
  float:right;
  color: var(--primary-color);
  cursor: move;
}
.back-icon-text{
  font-style: normal;
}
.profile-header{
  background: var(--primary-color);
  height: 15em;
}
.profile-content{
  margin-top: -8em;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  
 
}
.profile-content-card{
  box-shadow: 0 0 12px 4px rgba(0, 0, 0, 0.5);
}
.profile-card-shadow{
  box-shadow: 0 2px 3px -1px var(--primary-color)
}
/* Animation */

@keyframes fadeInUp {
  0% {
    transform: translateY(2%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}

.fadeInUp-animation {
  animation: 1s fadeInUp;
}


@keyframes fadeIn {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.fadeIn-animation {
  opacity: 1;
	animation-name: fadeIn;
	animation-iteration-count: 1;
	animation-timing-function: ease-in;
	animation-duration: .5s;
}

/**banner***/
.banner-container{
  margin-bottom: 2em;
}
.banner-text-container{
  background-color:var(--primary-color);
}
.banner-text{
  padding: 1em;
  color: var(--secondary-color);
}
.banner-bottom-line{
  color: var(--primary-color);
  opacity: 1;
  margin: unset;
  padding: unset;
  border: 2px solid;
}
.banner-text > *{
  margin-top: unset !important;
  margin-bottom: unset !important;
}
.config-banner-image-frame{
  height: 320px;
  border: 1px solid var(--primary-color);
  margin: auto;
}
.divider-solid{
  border-color:var(--primary-color) !important;
}

/**Coin**/
.ant-badge-multiple-words {
  top: -3px !important;
  left: 3px !important;
}
.coin-icon{
  background: #fdc502;
}
.dollar-sign svg {
  color: #f48631;
}

textarea:focus,
input:focus,
.footer-links:focus,
.social-icons a:focus,
select:focus,
button.btn-primary:focus,
button.btn-outline-primary:focus,
button.ant-btn:focus{
  border-color: var(--primary-color) !important;
  outline: 3px solid var(--focus-outline-color) !important ;
}
.add-remove-coins{
  display: flex;
}
.add-remove-coins .items{
  margin-right: 10px;
}
.add-remove-coins .middle{
  top: 4px;
  position: relative;
}
.add-remove-coins input{
  width: 60px;
  height: 32px;
}
.add-remove-coins button{
  height: 32px;
}
.add-remove-coins button i{
  top: -1px;
  position: relative;
}
.add-remove-coins .add-icon{
  color: var(--primary-color);
}
.add-remove-coins .remove-icon{
  color: #f62d51;
}

.admin-user-search-text span input:focus{
  outline: unset !important ;
}
.admin-user-group-label{
  color: var(--primary-color);
}

